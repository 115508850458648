import React, { useEffect, useState , useRef} from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import * as CONSTANTS from "../src/CONSTANTS";
import LogingFormRSVP from './components/LogingFormRSVP';
import RegisterFormRVP from './components/RegisterFormRVP';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">       
        {children}
      </div>
    </div>
  );
};

const ConferenceForm = () => {
  ////////////////////////////////////////////////
  const [processing, setProcessing]                        = useState(false);
  ////////////////////////////////////////////////
  const bottomPanelRef                                      = useRef<HTMLDivElement>(null);
  ////////////////////////////////////////////////
  const [phoneNumber, setPhoneNumber]                      = useState();

  const [flightList, setFlightList]                   = useState([]);
  const [chosenFlight, setChosenFlight]               = useState({});
  const [chosenFlightTime, setChosenFlightTime]       = useState("");
  const [confirmedFlight, setConfirmedFlight]         = useState(false);
  const [golfQues, setGolfQuest]                      = useState(false);
  ///////////////////////
  const params                                                          = useParams();
  //////////////////////
  const [nextButSpouse, setNextButSpouse]                           = useState("");
  const [nextButFlight, setNextButFlight]                           = useState("");
  const [nextCharge, setNextCharge]                                 = useState("");
  const [grFlightConfirm, setGrFlightConfirm]                       = useState("");
  const [nextButRoom, setNextButRoom]                               = useState("");
  const [nextButAccomo ,setNextButAccomo]                           = useState("");
  const [spouseDesigNext, setSpouseDesigNext]                       = useState("");
  //////////////////////
  const [pharmacyList, setPharmacyList]                             = useState([]);
  const [isLoginForm, setIsLoginForm]                               = useState(true);
  const [isModalOpen, setIsModalOpen]                               = useState(false);
  //////////////////////
  const [flatRoom, setFlatRoom]                                         = useState("");
  const [charge, setCharge]                                             = useState("");  
  const [chargeNtAtt, setChargeNotAtt]                                  = useState("");
  const [updateAmount, setUpdateAmount]                                 = useState("");  
  const [flightTimeSelisDisabled, setFlightTimeSelisDisabled]           = useState(false);
  //////////////////////
  const [conferenceType, setConferenceType]                       = useState('Franchisee, RP, Pharmacy Manager');
  const [store, setStore]                                         = useState('');

  const [dayBVisit, setdayBVisit]                                 = useState(false);
  const [isPCDT, setIsPCDT]                                       = useState(false);
  const [attendClinic, setAttendClinic]                           = useState(false);
  const [isSpouseComing, setIsSpouseComing]                       = useState(false);
  const [isSpousePharmacy, setIsSpousePharmacy]                   = useState(false);
  const [pCDTAttengClinic,setPCDTAttengClinic]                    = useState("")

  const [nextStep, setNextStep]                                   = useState();
  const [nextStepClinicRoom, setNextStepClinicRoom]               = useState();
  const [nextStepHigherAcc, setNextStepHigherAcc]                 = useState();
  const [accomodationUpgrade, setAccomodationUpgrade]             = useState(false);
  const [nextStepLast, setNextStepLast]                           = useState();
  const [spouseName, setSpouseName]                               = useState("");
  const [spousID, setSpouseID]                                    = useState("");
  const [spouseDietary, setSpouseDietary]                         = useState("None");
  const [spouseDesignationRole, setSpouseDesignationRole]         = useState("");
  const [spouseError, setSpouseError]                             = useState("");
  const [worksInPharmacy, setWorksInPharmacy]                     = useState(false);

  const [isFlight, setIsFlight]                                   = useState(false);
  const [isSharedRoom, setIsSharedRoom]            = useState(true);
  const [isGolfing, setIsGolfing]                  = useState(false);
  const [requireFlight, setRequireFlight]          = useState(false);
  const [airport, setAirport]                      = useState('');
  const [roomSharing, setRoomSharing]              = useState(true);
  const [sharingPartnerID, setSharingPartnerID]    = useState('');
  const [playGolf, setPlayGolf]                    = useState('no');
  const [gitfList, setGiftList]                    = useState([]);
  const [gift, setGift]                            = useState("");
  const [anyComment, setAnyComment]                = useState("");
  const [resvpMessage, setRSVPMessage]             = useState("");

  const [scrollHeight, setScrollHeight]            = useState(0);
  const [showForm, setShowForm]                    = useState(true);
  ////////////////////////
  const [pharmProcessing, setPharmProcessing]                   = useState(false);
  const [flightProcessing, setFlightProcessing]                 = useState(false);
  const [selectedVisitsOption, setSelectedVisitsOptions]        = useState([]);
  const [optionVisits, setOptionVisits]                         = useState([]);
  ///////////////////////
  
  useEffect(() => {
   
    if(Object.keys(params).length  ==  0){
      
      openModal();
    }else {
      setPhoneNumber(params.id);
    }
    getGePharms();
  },[]);

  useEffect(() => {  
    getGetGifts();
    getDayVisitLabels();
  },[conferenceType])

  ////------>
  useEffect(() => {
    if(isFlight){
      getAllTheFlights();
    }

    getStandardData();
   },[isFlight]); 
  ////------>

  useEffect(() => {
    if(scrollHeight > 0){
      window.scrollTo(0, scrollHeight);
    }
  },[scrollHeight]);

  const getGePharms = async () => {

    setPharmProcessing(true);
    try{
          let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: CONSTANTS.API_URL +"factories/pharmacy/consume",
            headers: { }
          };
        
          const res = await axios.request(config);
        
          setPharmacyList(res.data);
          setStore(res.data[0].name);
    }catch(err){
      console.log(err);
    }  
    setPharmProcessing(false);
  }
  
  const getGetGifts = async () => {

    let config = {};
    if(conferenceType === "Clinic Conference"){
      config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: CONSTANTS.API_URL +"factories/gifting/consume/type/clinic",
        headers: { }
      };
    }else {
      config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: CONSTANTS.API_URL +"factories/gifting/consume/type/franchise",
        headers: { }
      };
    }
   
  
    const res = await axios.request(config);

    setGiftList(res.data);
    //setGift(res.data[0]._id);

  }

  const getAllTheFlights = async () => {

    try{   
      setFlightProcessing(true);
        const res = await axios.get(CONSTANTS.API_URL +"events/flights/list");
         setFlightList(res.data);
         setFlightProcessing(false);
    }catch(erros){
      console.log(erros);     
      setFlightProcessing(false);
    }
  }

  const getStandardData = async () => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: CONSTANTS.API_URL +"factories/standard/consume",
      headers: { }
    };

    const res = await axios.request(config);

    //setCurrentIds(res.data[0]._id);
    setCharge(res.data[0].sfranchise_att);
    setChargeNotAtt(res.data[0].sfranchise_notatt);
    setFlatRoom(res.data[0].single_room);
    setUpdateAmount(res.data[0].conference_upgrade);
  }

  ////////////////////////////////////////
  //Update Function
  const handleConferenceTypeChange = (e) => {
    setConferenceType(e.target.value);

    if(e.target.value == "Day Visitor"){
      setdayBVisit(true);
    }

    //reset 
    setNextStep(false);
    setNextButSpouse("");
    setNextCharge("");
    setNextButFlight("");
    setNextButRoom("");           
    setNextButAccomo("");
    setNextStepClinicRoom(false);
    setNextStepHigherAcc(false);
    setNextStepLast(false);
  };

  const handleStoreChange = (val) => {
    //console.log("handleStoreChange");
    //console.log(val.target.value);
    setStore(val.target.value);
  };

  const handleIsPCDTChange = (e) => {
    setIsPCDT(e.target.value === 'yes');
  };

  const handleAttendClinicChange = (e) => {
    setAttendClinic(e.target.value === 'yes');
    setPCDTAttengClinic("");
  };

  const handleSpouseChange = (e) => {
    //setSpouse(e.target.value);
    setIsSpouseComing(e.target.value === 'yes');
  };

  const handleWorksInPharmacyChange = (e) => {
    setWorksInPharmacy(e.target.value === 'yes');
  };

  const handleSpouseNameChange = (e) => {
 
    setSpouseName(e.target.value);
  }

  const handleSpouseIDChange = (e) => {

    setSpouseID(e.target.value);
  }

  const handleSpouseDietaryUpdate = (e) => {
    setSpouseDietary(e.target.value);
  }

  const handleSpouseWorkInPharmacyChange = (e) => {
    setIsSpousePharmacy(e.target.value === 'yes');
  }

  const handleCheckSpouseDetailsAdded = (e) => {
       //check if name and ID text is filled in.
       if(spouseName.length > 1 && spousID.length === 13){
        setSpouseError("");
          setNextStep(true);
          setSpouseDesigNext(true);
       }else {
        setSpouseError("Please add valid name and a 13 digit ID Number");
       }
    //After a next
    //bottomPanelRef.current?.scrollIntoView();   
    setScrollHeight(document.body.scrollHeight)
  }

  const fromSpouseSectionNext = () => {
    setNextStep(true);
    setNextButSpouse("btn-grayout");
     //After a next
      //bottomPanelRef.current?.scrollIntoView();
      setScrollHeight(document.body.scrollHeight)
  }
  const handleDesignationRole = (e) => {
    setSpouseDesignationRole(e.target.value);
  }
  //////////////
  const chargeNextStepConfirm = (e) => {
    setNextStep(true);
    setNextCharge("btn-grayout");
     //After a next
     //bottomPanelRef.current?.scrollIntoView();
     setScrollHeight(document.body.scrollHeight)
    
  }
  //////////////
  const handleRequireFlightChange = (e) => {
    //console.log(e.target.value);
    //setRequireFlight(e.target.value === 'yes');
    setIsFlight(e.target.value === 'yes');
  };

  const handleGiftChangeSelection = (e) => {
      setGift(e.target.value);
  };

  const handleFlightChangeSelection = (e) => {
    //console.log(e.target.value);
    //console.log(flightList);
    const foundAirpot = flightList.filter(x => x._id === e.target.value);
    //console.log(foundAirpot);
    setChosenFlight(foundAirpot[0]);
  }

  const handleFlightTimeChangeSelection = (e) => {
    // console.log("handleFlightTimeChangeSelection");
  //check if attendClinic is true, it true select the first element
     if(attendClinic){
     // console.log("check if attendClinic is true, it true select the first element");
      const selectElement = document.querySelector('.flight-time-selection');
        if(selectElement && selectElement.options.length > 0) {
            selectElement.selectedIndex = 1;
           // console.log(selectElement.options[1].value);
            setChosenFlightTime(selectElement.options[1].value);
            setPCDTAttengClinic("A PCDT Pharmacist attending the clinic conference should only make use of the first flight.")
        }

     }else {
      console.log(e.target.value);
      setChosenFlightTime(e.target.value);
      setPCDTAttengClinic("")
     }
   
   // console.log("|||||||||||||||)))))))))");
  }

  const handleConfirmedFlight = (e) => {
    setConfirmedFlight(true);
    setGrFlightConfirm("btn-grayout");
    //After a next
    //bottomPanelRef.current?.scrollIntoView({behavior: "smooth"});
    setScrollHeight(document.body.scrollHeight)
  }
  ///////////
  const handleRoomSharingChange = (e) => {
    //console.log("handleRoomSharingChange");
    ///console.log(e.target.value);
    setRoomSharing(e.target.value === 'yes');
    setIsSharedRoom(e.target.value === 'yes');   
    setGolfQuest(true);
  };

  const handleSharingPartnerIDChange = (e) => {
    setSharingPartnerID(e.target.value);
  };

  //Golf Selection
  const handleGolfingChange = (e) => {
   // console.log(e.target.value);
    setPlayGolf(e.target.value);
    setIsGolfing(e.target.value === 'yes');
   // console.log(flightList);
    const selectElement = document.querySelector('.flight-time-selection');
    if(e.target.value == "yes"){
      //console.log("IN YES")
      if(selectElement && selectElement.options.length > 0) {
          selectElement.selectedIndex = 1;
          //console.log(selectElement.options[1].value);
          setChosenFlightTime(selectElement.options[1].value);
      }

      setFlightTimeSelisDisabled(true);

    }else {
     // console.log("IN NO")
      if(selectElement && selectElement.options.length > 0) {
          selectElement.selectedIndex = 0;
      }
      setFlightTimeSelisDisabled(false);
      setChosenFlightTime("");
    }
  };

  const handleUpgradeChange = () => {
    setAccomodationUpgrade(prevUpgrade => !prevUpgrade);
  };

  const handleCommentChange = (e) => {
    setAnyComment(e.target.value);
  }
  const finishFlightNextUpate = () => {
    console.log(conferenceType);
    //setNextStepLast(true);
    setNextButFlight("btn-grayout");
    //
    /*
    if clinic selection 
    set clinic next otherwise set higherAccomodation  
    */
    if(conferenceType === "Clinic Conference"){
      setNextStepClinicRoom(true)
    }else {
      setNextStepHigherAcc(true);
    }
    //After a next
    //bottomPanelRef.current?.scrollIntoView();
    setScrollHeight(document.body.scrollHeight)
  }
  const finalNexttNextUpate = () => {

    if(conferenceType === "Clinic Conference"){
      setNextButRoom("btn-grayout");
    }else{
      setNextButAccomo("btn-grayout");
    }
    setNextStepLast(true);
     //After a next
     //bottomPanelRef.current?.scrollIntoView();
     setScrollHeight(document.body.scrollHeight)
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCheckboxChangeDayVisit = (option) => {
    console.log(option);
    if (selectedVisitsOption.includes(option)) {
      setSelectedVisitsOptions(selectedVisitsOption.filter(item => item !== option));
    } else {
      setSelectedVisitsOptions([...selectedVisitsOption, option]);
    }

    console.log(selectedVisitsOption);
  };

  const getDayVisitLabels = async () => {
    try{
      const resVisit = await axios.get(CONSTANTS.API_URL +"factories/collect/visits");
      console.log("Re Visit");
      console.log(resVisit.data);
      if(resVisit.data.length > 0){
        setOptionVisits(resVisit.data);
      }
      
    }catch(err){
      console.log(err);
    }
 }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try{
      setProcessing(true);

      
      const rsvpObject = {
        "phonenumber" : phoneNumber,
        "data" : {
          "conference_type" :  conferenceType,
          "pharmacy_store" :  store,
          "PCDT" :  {
            "pcdt_pharmacist" : isPCDT ? "Yes" : "No",
            "pcdt_clinic" : attendClinic ? "Yes" : "No",
          },
          "spouse" :  {
            "spouse_coming": isSpouseComing,
            "spouse_name": spouseName,
            "spouse_id": spousID,
            "spouse_pharmacy_worker": isSpousePharmacy ? "Yes" : "No",
            "spouse_dietary" : spouseDietary,
            "spouse_designation": spouseDesignationRole,
            "spouse_charge": isSpousePharmacy ? "0.00" : (attendClinic ? charge : chargeNtAtt),
          },
          "flight_date": chosenFlightTime ?? "",
          "flight": chosenFlight.title ?? "",
          "flight_province": chosenFlight.province ?? "",
          "golf": playGolf,
          "sharing_room": roomSharing ? "Yes" : "No",
          "share_id": sharingPartnerID,
          "upgrade": accomodationUpgrade,
          "gift": gift,
          "any_comment": anyComment,
          "visit": {
            "day_visit" : dayBVisit,
            "selected_visits" : dayBVisit ? selectedVisitsOption : []
          }
        }
      }

      console.log(rsvpObject);
      const stringyObData = JSON.stringify(rsvpObject);
      //console.log(stringyObData);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: CONSTANTS.API_URL +'users/rsvp/update',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : stringyObData
      };
    
      const rsvpDetails = await axios.request(config);

      console.log("|||||||||+++++++++||||||");
      console.log(rsvpDetails);
      console.log("|||||||||+++++++++||||||");
     
      setProcessing(false);
      setRSVPMessage("You have confirmed your attendance to this year's event. Please get in touch with your Opportunity Manager should you require more information or further assistance.");
     
      setShowForm(false);

    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  };

  return (
    <div className="ctx-rsvp-container">
        {
          /*
          <button onClick={openModal}>Open Modal</button>
          <Modal isOpen={isModalOpen} onClose={closeModal}>
          */
        }
        <Modal isOpen={isModalOpen} >
          <div className="content-modal">
            {
              isLoginForm ? (
                <div className="form-flexer">
                  <LogingFormRSVP />
                  <div className="background-image1">
                      <div className="overlay-stunt">
                          <div className="vertical-center">
                             <button className="btn btn-primary trick-change" onClick={() => setIsLoginForm(false)}>Register</button>
                          </div>                        
                      </div>
                  </div>
                </div>
              )
              : 
              (
              <div className="form-flexer">                
                <div className="background-image2">
                   <div className="overlay-stunt">
                      <div className="vertical-center">
                           <button className="btn btn-primary trick-change" onClick={() => setIsLoginForm(true)}>Login</button>
                      </div>                          
                    </div>
                </div>
                <RegisterFormRVP />
              </div>
              )
            }
            
          </div>
        </Modal>

      {
        phoneNumber && (
          <div className="head-title">
              <h2>{phoneNumber}</h2>
          </div>
        )
      }
      {
        showForm && (
          <form onSubmit={handleSubmit}>
            <div className="box-container box-start">
              <label className="lbl-title bTitle2" >Conference </label>
              <label>Choose your role:</label>
              <select value={conferenceType} onChange={handleConferenceTypeChange} className="form-control">
                <option value="Franchisee, RP, Pharmacy Manager">Franchisee, RP, Pharmacy Manager</option>
                <option value="Clinic Conference">Clinic Conference</option>
                <option value="Day Visitor">Day Visitor</option>
              </select>
            </div>

            {conferenceType && (
              <>
                <div className="box-container">
                  <label  className="lbl-title">Select your Store:</label>
                  {
                      pharmProcessing && (  <img className="small-orange-spinner" src={CONSTANTS.ORANGE_PROCESSING}/> )
                  }                
                  {
                    pharmacyList && (
                      <select className="form-control" onChange={handleStoreChange}>
                        {
                          pharmacyList.map((pharmacy, index) => {
                              return (<option value={pharmacy.name} key={index}>{pharmacy.name}</option>)
                          })
                        }
                      </select>
                    )
                  }
          
                </div>

                {conferenceType === 'Franchisee, RP, Pharmacy Manager' && (
                  <>
                    <div className="box-container">
                      <label className="lbl-title">Are you a PCDT Pharmacist?</label>
                      <input type="radio" value="yes" checked={isPCDT} onChange={handleIsPCDTChange} /> <span className="lbl-rad-item">Yes</span>
                      <input type="radio" value="no" checked={!isPCDT} onChange={handleIsPCDTChange} /> <span className="lbl-rad-item">No</span>
                    </div>

                    {isPCDT && (
                      <div className="box-container">
                        <label className="lbl-title">Will you also be attending the clinic conference?</label>
                        <input type="radio" value="yes" checked={attendClinic} onChange={handleAttendClinicChange} /> <span className="lbl-rad-item">Yes</span>
                        <input type="radio" value="no" checked={!attendClinic} onChange={handleAttendClinicChange} /> <span className="lbl-rad-item">No</span>
                      </div>
                    )}
                  </>
                )}

                {conferenceType === 'Franchisee, RP, Pharmacy Manager' && (
                  <>
                    <div className="box-container">
                    <label className="lbl-title bTitle2" >Spouse </label>
                      <label className="lbl-title">Will you be accompanied by a spouse?</label>
                      <input type="radio" value="yes" checked={isSpouseComing} onChange={handleSpouseChange} /> <span className="lbl-rad-item">Yes</span>
                      <input type="radio" value="no" checked={!isSpouseComing} onChange={handleSpouseChange} /> <span className="lbl-rad-item">No</span>
                    </div>

                    {
                      isSpouseComing ? (
                              <div className="box-container">
                                <div className="in-container">
                                    <label className="lbl-title">Spouse Full Name:</label>
                                    <input type="text" className="form-control" onChange={handleSpouseNameChange} required/>
                                </div>
                                <div className="in-container">
                                    <label className="lbl-title">Spouse ID Number:</label>
                                    <input type="text" className="form-control" onChange={handleSpouseIDChange} required/>
                                </div>
                                <div className="in-container">
                                    <label className="lbl-title">Spouse Dietary:</label>
                                    <select className="form-control" onChange={handleSpouseDietaryUpdate} >
                                        <option value="None">None</option>
                                        <option value="Vegetarian">Vegetarian</option>
                                        <option value="Kosher">Kosher</option>
                                        <option value="Halaal">Halaal</option>
                                    </select>
                                </div>
                                <div className="in-container">
                                    <label className="lbl-title">Do they work in the pharmacy:</label>
                                    <input type="radio" value="yes" checked={isSpousePharmacy} onChange={handleSpouseWorkInPharmacyChange} /> <span className="lbl-rad-item">Yes</span>
                                    <input type="radio" value="no" checked={!isSpousePharmacy} onChange={handleSpouseWorkInPharmacyChange} /> <span className="lbl-rad-item">No</span>
                                </div>
                                  {
                                    isSpousePharmacy && (
                                      <div className="b-work x-button-pad">
                                        <div className="in-container">
                                            <label className="lbl-title">Designation in Store*:</label>    
                                          <input type="text" className="form-control" onChange={handleDesignationRole} required/> 
                                        </div>
                                    
                                      {
                                          spouseError && (
                                            <div className="mgtop10">
                                                <div className="alert alert-danger">
                                                  {spouseError}
                                                </div>
                                            </div>
                                          )
                                      }             
                                      </div>                                  
                                      
                                    )
                                     
                                  }
                                    <div className="in-container x-button-pad">
                                            <label className="lbl-title">Charge:</label>
                                            <div className="amount-figure">
                                              {
                                                isSpousePharmacy ? 
                                                  <span>  R 0.00</span> 
                                                  :
                                                    
                                                      attendClinic ? <span>  R {charge}</span> : <span>{chargeNtAtt}</span>
                              
                                              }
                                           
                                            </div>
                                          <div className="row nk-next-step">
                                              <div className="col-md-4">
                                                <button type="button" className={"btn btn-outline-primary mgtop10 " + nextCharge } onClick={() => chargeNextStepConfirm()}>
                                                  Next
                                                </button> 
                                              </div>    
                                          </div>  
                                      </div>
                                
                              </div>
                        )
                        : 
                        <div className="row nk-next-step">
                            <div className="col-md-4">
                              <button type="button" className={"btn btn-outline-primary mgtop10 " + nextButSpouse} onClick={() => fromSpouseSectionNext()}>
                                Next
                              </button> 
                            </div>    
                        </div>
                    }
                
                  </>
                )}

                {
                  //clinic = something && franchise section complete
                ((conferenceType == "Clinic Conference") || nextStep) 
                && (
                  <>
                    <div className="box-container">
                       
                       <div className="in-container">
                             
                              <div className="golf-block">
                                {
                                    (conferenceType != "Clinic Conference") && (
                                      <>
                                        <label className="lbl-title bTitle2">Golf</label>
                                          <div className="in-cont">                 
                                            <label className="lbl-title">Will you be playing golf?</label>
                                              <input type="radio" value="yes" checked={isGolfing} onChange={handleGolfingChange} />  <span className="lbl-rad-item">Yes</span>
                                              <input type="radio" value="no" checked={!isGolfing} onChange={handleGolfingChange} />  <span className="lbl-rad-item">No</span>                 
                                          </div>
                                      </>
                                    )
                                  }
                              </div>
                        </div>
                      <label className="lbl-title bTitle2">Flights</label>
                      <div className="in-container">
                        <label className="lbl-title">Do you require a flight?</label>
                        <input type="radio" value="yes" checked={isFlight} onChange={handleRequireFlightChange} />  <span className="lbl-rad-item">Yes</span>
                        <input type="radio" value="no" checked={!isFlight} onChange={handleRequireFlightChange} />  <span className="lbl-rad-item">No</span>
                      </div>
                   
                    </div>

                    {isFlight ? (
                      <div className="box-container">
                    
                        <label className="lbl-title bTitle2">Airport:</label>
                      
                        <div className="row gin-box">
                            
                            
                              <div className="">
                                <label className="lbl-title">Select Your Airpot:</label>
                              </div>
                              {
                                  flightProcessing && (  <img className="small-orange-spinner" src={CONSTANTS.ORANGE_PROCESSING}/> )
                              }
                              {
                                flightList && (
                                  <select className="form-control" onChange={handleFlightChangeSelection}>
                                    <option value="_"> </option>
                                    {
                                      flightList.map((flight, index) => {
                                        return (<option value={flight._id} key={index}>{flight.title}</option>)
                                      })
                                    }
                                  </select>
                                )
                              }
                              {
                                      chosenFlight.flightoption && chosenFlight.flightoption !== null && chosenFlight.flightoption !== undefined && (
                                        <div className="pdtop10">
                                          <button type="button" className={"btn btn-outline-primary " + grFlightConfirm} onClick={handleConfirmedFlight}>
                                            Next
                                          </button>
                                       </div>
                                      )
                                    }
                           
                        </div>
                        {
                          confirmedFlight && (
                            <div className="row in-container">
                                <div className="mgtop10">
                                  <div className="alert alert-success">You have confirmed Flight Selection</div>
                                </div>
                                <div className="mgtop10">
                                    <div className="row ">
                                          <div className="col-md-4">
                                            <button type="button" className={"btn btn-outline-primary mgtop10 " + nextButFlight} onClick={() => finishFlightNextUpate()}>
                                              Next
                                            </button> 
                                          </div>    
                                      </div>
                                </div>
                              </div>
                          )
                        }
                      </div>
                    )
                    :
                      (<div className="mgtop10">
                            <div className="row nk-next-step">
                                <div className="col-md-4">
                                  <button type="button" className={"btn btn-outline-primary mgtop10 " + nextButFlight} onClick={() => finishFlightNextUpate()}>
                                    Next
                                  </button> 
                                </div>    
                            </div>
                      </div>)
                    }

                    {/* Add options according to the flight chosen */}
                  </>
                )}

                { nextStepClinicRoom && (
                  <>
                    <div className="box-container">
                      <label className="lbl-title bTitle2" >Sleeping arrangements:</label>
                      <label className="lbl-title">Select Your sleeping arrangements:</label>
                      {/* Add information about the room */}
                      <input type="radio" value="yes" checked={isSharedRoom} onChange={handleRoomSharingChange} />  <span className="lbl-rad-item">Share a room</span>
                      <input type="radio" value="no" checked={!isSharedRoom} onChange={handleRoomSharingChange} />  <span className="lbl-rad-item">Single room</span>
                    </div>

                    {roomSharing ? (
                          <div className="box-container">
                            <label className="lbl-title">Intended sharing partner's ID number*:</label>
                            <input type="text" value={sharingPartnerID} onChange={handleSharingPartnerIDChange}  className="form-control" required/>
                          </div>
                        )
                        : (
                          <div className="box-container">
                            <div className="amount-figure">R {flatRoom} </div> <span>Note that this amount will be for your account.</span>
                          </div>
                        )
                    }  
                    <div className="in-container">
                  
                      <div className="mgtop10">
                            <div className="row nk-next-step">
                                  <div className="col-md-4">
                                    <button type="button" className={"btn btn-outline-primary mgtop10 " + nextButRoom} onClick={() => finalNexttNextUpate()}>
                                      Next
                                    </button> 
                                  </div>    
                              </div>
                        </div>
                    </div>          
                  </>
                )}
              {/* Franchisee Accomodation */}
              {
                nextStepHigherAcc && (
                  <>
                    <div className="box-container">
                      <label className="lbl-title bTitle2">Accomodation:</label>
                      <div className="in-container">
                        <label className="lbl-title">
                          Your accommodation at the <strong>Garden Court, Sandton City Hotel</strong> is complementary. 
                          You can choose to upgrade your stay to the <strong>Sandton Sun Hotel, Sandton City</strong>, payble at R {updateAmount} (excl.) per room, per night. </label>
                        <div className="up-item pd10">
                        <input 
                              type="checkbox" 
                              checked={accomodationUpgrade} 
                              onChange={handleUpgradeChange} 
                            />
                            <span className="updg-text">Upgrade</span>
                            {
                              accomodationUpgrade &&(
                                <div className="mgtop10">
                                  <div className="alert alert-success">
                                    Upgrade has been confirmed.
                                  </div>
                                </div>
                              )
                            }
                        </div>
                        <div className="in-container x-button-pad">
                      
                          <div className="mgtop10">
                                <div className="row nk-next-step">
                                      <div className="col-md-4">
                                        <button type="button" className={"btn btn-outline-primary mgtop10 " + nextButAccomo} onClick={() => finalNexttNextUpate()}>
                                          Next
                                        </button> 
                                      </div>    
                                  </div>
                            </div>
                        </div>
                      </div>
                      
                    </div>
                  </>
                )
              }
              {
                //Day visit
                dayBVisit && (
                  <div className="box-container">
                       <label className="lbl-title bTitle2" >Day Visit </label>
                       <label className="lbl-title" >Select Your Options</label>
              
                       <div className="opt-visit">
                        {
                          optionVisits.map((option, index) => (
                            <div key={index} className="line-check form-control">
                              <div className="check-item ">
                                <input
                                  type="checkbox"
                                  id={`option-${index}`}
                                  checked={selectedVisitsOption.includes(option)}
                                  onChange={() => handleCheckboxChangeDayVisit(option)}
                                />
                              </div>
                              <div htmlFor={`option-${index}`} className="frm-check">
                                {`${option.date} – ${option.label} (${option.price})`}
                              </div>
                            </div>
                          ))}
                      </div>

                      {
                        (selectedVisitsOption.length > 0) && (
                          <div className="mgtop10">
                              <div className="row nk-next-step">
                                    <div className="col-md-4">
                                      <button type="button" className={"btn btn-outline-primary mgtop10 " + nextButAccomo} onClick={() => finalNexttNextUpate()}>
                                        Next
                                      </button> 
                                    </div>    
                                </div>
                          </div>
                        )
                      }
                  </div>
                )
              }
              {nextStepLast && (
                  <>
                    <div className="box-container final-page">
            
                      <div className="in-container">                 
                        <label className="lbl-title">Anything you would like to share or bring to our attension?</label>
                        <textarea className="form-control" onChange={handleCommentChange}></textarea>                 
                      </div>
                      <div className="form-group frm-button">
                          <button type="submit" className="btn btn-primary">SEND</button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

          </form>
        )
      }
      <div className="pd10">
         
      </div>
      {
        resvpMessage.length > 0 && (
          <div className="rsvp-container">
             <h2>Thank you!</h2>
            <div className="">
              {resvpMessage}
            </div>
          </div>
         )
      }
     
      {
        processing && (
          <div className="img-source">
              <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
          </div>
          )
       }
    </div>
  );
};

export default ConferenceForm;