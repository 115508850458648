import React from 'react';
import ConferenceForm from './ConferenceForm';

function Home() {
  return (
    <div className="container">
        <ConferenceForm />
    </div>
  )
}

export default Home