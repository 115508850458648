import React, { useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

function LogingFormRSVP() {

    const [processing, setProcessing]                                  = useState(false);
    const [phoneNumber, setPhoneNumber]                                = useState();
    const [password, setPassword]                                      = useState();
    const [message, setMessage]                                        = useState("");
    const [resetPhoneNumber, setResetPhoneNumber]                   = useState();
    ///////////////////////////////////////////
    const [pharmProcessing, setPharmProcessing]                        = useState(false);
    const [declineFormally, setDeclineFormally]                        = useState(false);
    //////////////////////

    const [resetPasswordMessage, setResetPasswordMessage]          = useState();
    const [openResetPassword, setOpenResetPassword]                   = useState(false);
    const [pharmacyList, setPharmacyList]                             = useState([]);
    const [messageDecline, setMessageDecline]                         = useState("");

    const navigate                                                     = useNavigate();

    const nameDeclineRef                                               = useRef();
    const surnameDeclineRef                                            = useRef();
    const emailDeclineRef                                              = useRef();
    const pharmNameDeclineRef                                          = useRef();
    const roleDeclineRef                                               = useRef();
    const reasonDeclineRef                                             = useRef();

    useEffect(() => {
        getGePharms();
    },[])

    const getGePharms = async () => {

        setPharmProcessing(true);
        try{
              let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL +"factories/pharmacy/consume",
                headers: { }
              };
            
              const res = await axios.request(config);
            
              setPharmacyList(res.data);
             // setStore(res.data[0].name);
        }catch(err){
          console.log(err);
        }  
        setPharmProcessing(false);
    }
  
      
    const phoneNumberUpdate = (e) => {
        setPhoneNumber(e.target.value);
    }  

    const phoneResetNumberUpdate = (e) => {
        setResetPhoneNumber(e.target.value);
    }

    const passWordUpdate = (e) => {
        setPassword(e.target.value);
    }   

    async function HandleLogin(e) {
        e.preventDefault();  

        try{
            const loginNow = {
                "phonenumber" : phoneNumber,
                "password"  : password
            }
            const responseReg = await axios.post(CONSTANTS.API_URL+"auth/login", loginNow);
        
            if(responseReg.status === 200 ){
                console.log(responseReg.data.phonenumber);
             
                navigate("/" + responseReg.data.phonenumber);
                window.location.reload(); 
            }else {
                setMessage("Login issue, please try again later.");
            }
        }catch(err){
            console.log(err);
            setProcessing(false);
            setMessage("Invalid Logins. Please register to proceed.");
        }
    }

    async function HandleResetPassword(e) {
        e.preventDefault();  
        setProcessing(true);

        try{

            const phone = {
                "phonenumber": resetPhoneNumber
            }

            //console.log(CONSTANTS.API_URL+"auth/passreset");

            const resPhone = await axios.put(CONSTANTS.API_URL + "auth/passreset", phone);

           //console.log(resPhone);
        
            if(resPhone.status === 200){
                setResetPasswordMessage(resPhone.data.message +" [ "+ resPhone.data.new_pass +" ]");
                e.target.reset();
            }            
          
            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
            if(err.response.status == 401){
                setResetPasswordMessage(err.response.data);
            }

            console.log("err");
        }
    }

    async function HandleDeclineRequest(e) {
        e.preventDefault();  
        setMessageDecline("");
        try{
            setProcessing(true);
                const objectItem = {
                    "name" : nameDeclineRef.current.value,
                    "surname" : surnameDeclineRef.current.value,
                    "email" : emailDeclineRef.current.value,
                    "pharmacy" : pharmNameDeclineRef.current.value,
                    "role" : roleDeclineRef.current.value,
                    "reason" : reasonDeclineRef.current.value
                }
                console.log(objectItem);
                const resObs = await axios.post(CONSTANTS.API_URL+"factories/create/decline", objectItem);
                
                if(resObs.status == 201){

                    setMessageDecline("Submission was successful.");

                    nameDeclineRef.current.value                    = "";
                    surnameDeclineRef.current.value                 = "";
                    emailDeclineRef.current.value                   = "";

                }else {
                    setMessageDecline("Submission was not successful, please try again later.");
                }
                setProcessing(false);
        }catch(err){
            console.log(err);
            setMessageDecline("Something went wrong, please try again later.");
            setProcessing(false);
        }
    }

    const viewDeclineForm = () => {
        setDeclineFormally(!declineFormally);
    }


  return (
    <div className="form-block-container toplogin">
        {
            declineFormally ?
            <div className="main-decline-item">
                <h2>Formal Decline</h2>
                <div className="login-form decline-frm">
                   <form onSubmit={HandleDeclineRequest}>   
                            <div className="form-group">
                                <div className="lbldesc">Name:</div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        clasName="form-control"
                                        ref={nameDeclineRef}
                                        required
                                        />
                                </div> 
                            </div>  
                            <div className="form-group">
                                <div className="lbldesc">Surname:</div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        clasName="form-control"
                                        ref={surnameDeclineRef}
                                        required
                                        />
                                </div> 
                            </div>  
                            <div className="form-group">
                                <div className="lbldesc">Email:</div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        clasName="form-control"
                                        ref={emailDeclineRef}
                                        required
                                        />
                                </div> 
                            </div>  
                            <div className="form-group">
                                <div className="lbldesc">Pharmacy:</div>
                                <div className="form-group">
                            

                                        {
                                          pharmProcessing && (  <img class="small-orange-spinner" src={CONSTANTS.ORANGE_PROCESSING}/> )
                                        }                
                                        {
                                        pharmacyList && (<select  clasName="form-control" ref={pharmNameDeclineRef}>
                                            {
                                            pharmacyList.map((pharmacy) => {
                                                return (<option value={pharmacy.name}>{pharmacy.name}</option>)
                                            })
                                            }
                                        </select>
                                        )
                                       }
                                </div> 
                            </div>  
                            <div className="form-group">
                                <div className="lbldesc">Conference Role:</div>
                                <div className="form-group">
                                    <select 
                                        clasName="form-control"
                                        ref={roleDeclineRef}
                                        >
                                         <option value="Franchisee, RP, Pharmacy Manager">Franchisee, RP, Pharmacy Manager</option>
                                        <option value="Clinic Conference">Clinic Conference</option>
                                    </select>
                                </div> 
                            </div>  
                            <div className="form-group">
                                <div className="lbldesc">Reason for decline:</div>
                                <div className="form-group">
                                    <select 
                                        clasName="form-control"
                                        ref={reasonDeclineRef}
                                        >
                                        <option value="Unable due to prior arrangements">Unable due to prior arrangements</option>
                                        <option value="⁠Not interested in attending">⁠Not interested in attending</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div> 
                            </div> 
                            
                            <div className="form-row-sub">
                                <button type="submit" className="btn btn-primary" disabled={processing}>Send</button>                             
                            </div>
                    </form>
                    <div className="head2">
                      <span className="link-formal" onClick={() => viewDeclineForm()}>Click link</span> here to login instead.
                    </div>
                    {
                        messageDecline && (
                            <div className="alert alert-warning">
                                {messageDecline}
                            </div>
                        )
                    }
                </div>
            </div>
            :
            <div className="main-login-item">
                <h2>Login</h2>
                <div className="login-form">
                    <div className="head1">
                        Please Register to RSVP for this year's conference 08 - 11 May 2024.  If you are unable to attend please formally decline by filling in the form on this <span className="link-formal" onClick={() => viewDeclineForm()}>link</span>.
                    </div>
                    <form onSubmit={HandleLogin}>   
                            <div className="form-group">
                                <div className="lbldesc">Phone Number:</div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        className="form-control"
                                        onChange={phoneNumberUpdate}
                                        maxLength="10"
                                        required
                                        />
                                </div> 
                            </div>  
                            <div className="form-group">
                                <div className="lbldesc">Password:</div>
                                <div className="form-group">
                                    <input 
                                        type="password"
                                        className="form-control"
                                        onChange={passWordUpdate}
                                        required
                                        />
                                </div> 
                            </div>   
                            
                            <div className="form-row-sub">
                                <button type="submit" className="btn btn-primary" disabled={processing}>Login</button>                             
                            </div>
                    </form>
                   
                    <div className="pdtop30">
                       <img src="https://mevent.co.za/images/sandtonvention.png" className="logo-link" />
                    </div>
                    <div className="reset-dt pd10">
                        <span className="link-formal" onClick={() => setOpenResetPassword(true)}>Need to reset your password?</span> 
                        <div className="reset-form pdtop10">
                            {
                                openResetPassword && (
                                    <div className="box-password-reset">
                                        <span className="cl-rest" onClick={() => setOpenResetPassword(false)}>X</span>
                                        RESET PASSWORD 
                                        <form onSubmit={HandleResetPassword}>   
                                            <div className="form-group">
                                                <div className="lbldesc">Enter Phone Number for Reset:</div>
                                                <div className="form-group">
                                                    <input 
                                                        type="text"
                                                        className="form-control"
                                                        onChange={phoneResetNumberUpdate}
                                                        maxLength="10"
                                                        required
                                                        />
                                                </div> 
                                            </div>
                                            <div className="form-row-sub">
                                                <button type="submit" className="btn btn-primary" disabled={processing}>RESET</button>                             
                                            </div>
                                        </form>
                                        <div className="pd10">
                                            {
                                                resetPasswordMessage && (
                                                    <div className="alert alert-warning">
                                                        {resetPasswordMessage}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
            
        <div className="message-error">
            {
                message && (
                    <div className="alert alert-danger mgtop10">
                        {message}
                    </div>
                )
            }
        </div>

        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
    </div>
  )
}

export default LogingFormRSVP