import React, {useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import {useNavigate } from 'react-router-dom';

function RegisterFormRVP() {
    const [processing, setProcessing]                        = useState(false);

    const [message, setMessage]                              = useState("");
    const [phoneNumber, setPhoneNumber]                      = useState();
    const [password, setPassword]                            = useState();
    const [idNumber, setIDNumber]                            = useState();
    const [surname, setSurname]                              = useState();
    const [name, setName]                                    = useState();
    const [jobTitle, setJobTitle]                            = useState();
    const [emailAddress, setEmailAddress]                    = useState();
    const [dietary, setDietary]                              = useState();

    const navigate                                           = useNavigate();

    const phoneNumberUpdate = (e) => {
        setPhoneNumber(e.target.value);
    }  
    const passWordUpdate = (e) => {
        setPassword(e.target.value);
    }  
    const identityNumberUpdate = (e) => {
        setIDNumber(e.target.value);
    }  
    const personNameUpdate = (e) => {
        setName(e.target.value);
    }  
    const personSurnameUpdate = (e) => {
        setSurname(e.target.value);
    }  
    const jobTitleUpdate = (e) => {
        setJobTitle(e.target.value);
    }  
    const emailAddressUpdate = (e) => {
        setEmailAddress(e.target.value);
    }  
    const dietaryUpdate = (e) => {
        setDietary(e.target.value);
    }

    async function HandleRegister(e) {
        e.preventDefault();  
        console.log("Register attempt");
        setProcessing(true);
        try{
            console.log(phoneNumber);
            console.log(password);
            const registerNow = {
                "phonenumber" : phoneNumber,
                "idnumber"  : idNumber,
                "name"  : name,
                "surname"  : surname,
                "email"  : emailAddress,
                "profession"  : jobTitle,
                "dietary": dietary,
                "password"  : password
            }

            const responseReg = await axios.post(CONSTANTS.API_URL+"auth/register", registerNow);
            console.log("responseReg");
            console.log(responseReg.data);
            setProcessing(false);
            console.log(responseReg);
            console.log(responseReg.status);

            if(responseReg.status === 201 ){
                console.log(responseReg.data.phonenumber);
                //navigate('/'+ phoneNumber);
                navigate("/" + responseReg.data.phonenumber);
                window.location.reload(); 
            }else {
                setMessage("Registration issue, please try again later.");
            }
        }catch(err){
            console.log(err);
            setProcessing(false);
            if(err.response.status == 409){
                setMessage(err.response.data);
            }else {
                setMessage("Something went wrong, please try again later.");
            }            
        }
    }
  return (
    <div className="form-block-container topreg">
        <h2>Register</h2>
        <div className="reg-form">
            <form onSubmit={HandleRegister}>   
                <div className="form-group">
                    <div className="lbldesc">Phone Number:</div>
                    <div className="form-group-in">
                        <input 
                            type="text"
                            className="form-control"
                            onChange={phoneNumberUpdate}
                            defaultValue="0"
                            maxLength="10"
                            required
                            />
                    </div> 
                </div>  
                <div className="form-group">
                    <div className="lbldesc">ID Number:</div>
                    <div className="form-group-in">
                        <input 
                            type="text"
                            className="form-control"
                            onChange={identityNumberUpdate}
                            required
                            />
                    </div> 
                </div>  
                <div className="form-group">
                    <div className="lbldesc">Name</div>
                    <div className="form-group-in">
                        <input 
                            type="text"
                            className="form-control"
                            onChange={personNameUpdate}
                            required
                            />
                    </div> 
                </div>  
                <div className="form-group">
                    <div className="lbldesc">Surname:</div>
                    <div className="form-group-in">
                        <input 
                            type="text"
                            className="form-control"
                            onChange={personSurnameUpdate}
                            required
                            />
                    </div> 
                </div>  
                <div className="form-group">
                    <div className="lbldesc">Job Title:</div>
                    <div className="form-group-in">
                        <input 
                            type="text"
                            className="form-control"
                            onChange={jobTitleUpdate}
                            required
                            />
                    </div> 
                </div>  
                <div className="form-group">
                    <div className="lbldesc">Email Address:</div>
                    <div className="form-group-in">
                        <input 
                            type="text"
                            className="form-control"
                            onChange={emailAddressUpdate}
                            required
                            />
                    </div> 
                </div>  
                <div className="form-group">
                    <div className="lbldesc">Dietary:</div>
                    <div className="form-group-in">
                        <select 
                            className="form-control"
                            onChange={dietaryUpdate}
                            >
                            <option value="None">None</option>
                            <option value="Vegetarian">Vegetarian</option>
                            <option value="Kosher">Kosher</option>
                            <option value="Halaal">Halaal</option>
                        </select>
                    </div> 
                </div>  
            
                <div className="form-group">
                    <div className="lbldesc">Password:</div>
                    <div className="form-group-in">
                        <input 
                            type="password"
                            className="form-control"
                            onChange={passWordUpdate}
                            required
                            />
                    </div> 
                </div>   
            
                <div className="form-row-sub">
                    <button type="submit" className="btn btn-primary" disabled={processing}>Register</button>                             
                </div>
            </form>
        </div>

            <div className="message-error mgtop10">
                    {
                        message && (
                            <div className="alert alert-danger">
                                {message}
                            </div>
                        )
                    }
            </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
    </div>
  )
}

export default RegisterFormRVP