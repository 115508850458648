/*
export const API_URL = "http://localhost:55021/api/";
export const SESSION_COOKIE = "meventLocalRSVP";
*/

export const API_URL = "https://mevent-phone-4f690ce5228b.herokuapp.com/api/"
export const SESSION_COOKIE = "meventRAaaaRSVP";

export const SMALL_PROCESSING = "https://api.picnick.co.za/assets/smallprocess.gif";
export const BLUE_PROCESSING = "https://cjmarketing.co/image_directory/blue-load.gif";
export const ORANGE_PROCESSING = "https://cjmarketing.co/image_directory/orange_circles.gif";
export const IMAGE_PROFILE = "https://newclient.cjdsa.co.za/images/base/profile.jpg";